
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    import {str} from '@lit/localize';

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h4e032a9ba1fe5753': html`Sto leggendo la mia configurazione da: <code>${0}</code>`,
'h4ecc1b3d116a58d7': html`In attesa che il mio <code>${0}</code> attributo venga definito`,
'hab2fdbba66c93e40': html`recupero della configurazione da <code>${0}</code>`,
's032e92c80863cc5a': str`asincrono in corso`,
's037df8d2f49aa980': str`caricato`,
's6ed675fff1cc77ed': str`Cose che sto fornendo alla sottostruttura dei componenti:`,
's84ae32ba760890f9': str`Oh, sono la shell dell'app!`,
's9e9b04c54923ae13': str`inattivo`,
's9f7452dd75d54d31': str`errore`,
'seac9afef1a00b5e8': str`In attesa`,
'sf48d916fa3ea8dd7': str`Lingua:`,
'sfa124b47e896b2aa': str`In attesa della definizione dello stato in sospeso`,
    };
  